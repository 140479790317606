import { useStyletron } from 'baseui'
import React, { useEffect, useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'

const cocaCola = require('../../../assets/images/cocacola.svg') as string
const velostics = require('../../../assets/images/velostics-logo.svg') as string

const Logo = () => {
  const [css] = useStyletron()
  const location = useLocation()
  const [logo, setLogo] = useState('')

  useEffect(() => {
    setLogo(
      location.pathname === '/consignee' || location.pathname === '/supplier' ? velostics : cocaCola
    )
  }, [location])

  return (
    <NavLink to="/" activeStyle={{ display: 'block' }}>
      <img
        className={css({
          width: '80px',
          height: '28px',
          top: '25px'
        })}
        src={logo}
        id="appLogo01"
        alt="Velostics Logo"
      />
    </NavLink>
  )
}

export default Logo
